import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function VerfahrenPage() {
  return (
    <Layout className="no-sidebar">
      <SEO title="Verfahren" description="Karbonisierung - ein Mehrwert gegenüber der herkömmlichen Gewinnung von erneuerbarer Energie aus Biomasse – ökologisch und ökonomisch sinnvoll –" />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Verfahren</h2>
            </header>

            <h3>
              Karbonisierung - ein Mehrwert gegenüber der herkömmlichen Gewinnung von erneuerbarer Energie aus Biomasse – ökologisch und ökonomisch sinnvoll –
            </h3>
            
            <p className="highlight">In Biomasse steckt viel mehr als nur die Energie der Zukunft</p>

            <div className="image right"><Image /></div>

            <p>
              Die Karbonisierung – die Konversion von natürlichem Material zu Pflanzenkohle, Futterkohle, Aktivkohle oder Düngemittel-Substrat – ist ein seit vielen Jahrhunderten bekannter Prozess. Doch nur in den von uns favorisierten Verfahren lassen sich die Prozessparameter so gezielt steuern, dass Karbon-Produkte in verschiedenen Qualitätsstufen erzeugt und Nährstoffe schonend recycelt werden können - effizient, umweltfreundlich und ohne schädliche Nebenprodukte.
            </p>

            <h3>Thermische Hygienisierung - Wertvolles Karbonisat</h3>

            <p>
              Bei dieser Technologie handelt es sich um ein kontinuierliches Verfahren, das nach dem Prinzip der trockenen Karbonisierung arbeitet. Die getrocknete Biomasse wird durch 500 - 700°C heiße Reaktoren geführt. Dort wird sie aber nicht verbrannt, sondern zuerst schonend entgast und anschließend durch gezielte Luftzugabe verkohlt. Damit ist die Biomasse vollständig hygienisiert.
            </p>
            <p>
              Gleichzeitig bewirkt der Transport des Materials im Reaktor mittels Förderschnecken eine vollständige Durchkohlung und vermeidet so das Entstehen von Schadstoffen wie PAK´s und Dioxinen. Da in diesem Verfahren Behandlungsparameter wie die Temperaturführung, die Verweilzeit bei der Karbonisierung und die Primärluftzugabe gezielt gesteuert werden können, lassen sich Pflanzenkohle, Futterkohle, Aktivkohle oder Phosphordünger von höchster Qualität herstellen.
            </p>

            <h3>Keine Problemstoffe</h3>

            <p>
              Da die in den Reaktoren entstehenden brennbaren Gase vom Material entkoppelt und in einer nachgeschalteten Brennkammer durch eine flammlose Oxidation bei rund 1.000°C vollständig verbrannt werden, bildet das  Verfahren wenig thermisches NOX. Auch entstehen so keine Problemstoffe wie Öle oder Teere, da das Schwelgas nicht abgekühlt, sondern durch die vollständige Oxidation in der Nachbrennkammer thermisch gereinigt wird. Bei der Verarbeitung von Klärschlamm werden außerdem in einer nachgeschalteten Abgasreinigungsstufe die sauren Schadgase in einem alkalischen Rauchgaswäscher aus dem Abgas entfernt und flüchtige Klärschlammbestandteile wie Quecksilber an einen Aktivkohlefilter adsorbiert.
            </p>

            <h3>Nutzbare Energie – autothermer Prozess</h3>

            <p>
              Die Karbonisierung verläuft zudem autotherm. Das heißt, es wird lediglich etwas externe Startenergie benötigt und anschließend wird für die Aufrechterhaltung des Prozesses nur die in der eingebrachten Biomasse enthaltene Energie genutzt. Dazu werden die heißen Rauchgase aus der Brennkammer in den Mantel des Reaktors geleitet, was zum Durchtrocknen und Karbonisieren der Biomasse führt. Darüber hinaus entsteht ein Energieüberschuss in Form von Wärme, die beispielsweise für die Trocknung feuchter Biomassen oder zum Heizen genutzt werden kann.
            </p>
          </article>
        </div>
      </div>
    </Layout >
  )
}

function Image() {
  const { iceFloes } = useStaticQuery(graphql`
    query {
      iceFloes: file(relativePath: { eq: "ali-abdul-rahman-595218-unsplash.jpg" }) {
        childImageSharp { fluid(maxWidth: 600) { ...GatsbyImageSharpFluid } }
      }
    }
  `)

  return <Img fluid={iceFloes.childImageSharp.fluid} />
}
